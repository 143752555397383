var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editor", style: { width: _vm.width } }, [
    _c("div", { ref: "toolbar", staticClass: "toolbar" }),
    _c("div", {
      ref: "editor",
      staticClass: "text",
      style: { height: _vm.height },
      attrs: { id: "editor" },
    }),
    _c("div", {
      ref: "editor_line",
      staticClass: "editor_line",
      attrs: { id: "editor_line" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }