<template lang="html">
  <div class="editor" :style={width}>
    <!-- {{value}} -->
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text" :style={height} id="editor"></div>
    <div ref="editor_line" id="editor_line" class="editor_line"></div>
  </div>
</template>

<script>
/**
 * 组件使用规则
 * @attribute width 富文本的高度
 * @attribute height 富文本的宽度
 * @attribute v-model 富文本内容
 * <my-tinymcet v-model="data" width="700px" height="300px"></my-tinymcet>
 */
import E from 'wangeditor'
export default {
  name: 'TinymceText',
  data () {
    return {
      editor: null,
      editorContent: null,
      isChange: false
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '800px'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  watch: {
    value (val) {
      if (!this.isChange) {
        this.editor.txt.html(val)
        // 使用 v-model 时，设置初始值
      }
      this.isChange = false
    }
  },
  mounted () {
    this.seteditor()
    let srcPosiY = 0
    let destPosiY = 0
    let moveY = 0
    let isMouseDown = false
    let destHeight = 30
    $('#editor_line').mousedown(function (e) {
      srcPosiY = e.pageY
      isMouseDown = true
    })
    $(document).bind('click mouseup', function (e) {
      if (isMouseDown) {
        isMouseDown = false
      }
    }).mousemove(function (e) {
      destPosiY = e.pageY
      moveY = destPosiY - srcPosiY
      srcPosiY = destPosiY
      destHeight = $('#editor').height() + moveY
      if (isMouseDown) {
        $('#editor').css('height', destHeight > 150 ? destHeight : 150)
      }
    })
  },
  methods: {
    seteditor () {
      this.editor = new E(this.$refs.toolbar, this.$refs.editor)
      this.editor.customConfig.uploadImgShowBase64 = true // base 64 存储图片
      this.editor.customConfig.debug = true // 开启debug模式
      this.editor.customConfig.pasteFilterStyle = false // 关闭粘贴内容中的样式
      this.editor.customConfig.pasteIgnoreImg = true // 忽略粘贴内容中的图片
      this.editor.customConfig.uploadImgHeaders = {} // 自定义 header

      // 上传图片
      // this.editor.customConfig.uploadImgServer = process.env.VUE_APP_API_ADDRESS + 'common/file/uploadMap' // 配置服务器端地址
      this.editor.customConfig.uploadImgServer = process.env.NODE_ENV === 'development' ? '/api/common/file/uploadMaps' : process.env.VUE_APP_API_ADDRESS + 'common/file/uploadMaps' // 配置服务器端地址
      this.editor.customConfig.uploadFileName = 'files' // 后端接受上传文件的参数名
      this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
      this.editor.customConfig.uploadImgMaxLength = 30 // 限制一次最多上传 5 张图片
      this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间

      // 配置菜单
      this.editor.customConfig.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo' // 重复
      ]
      this.editor.customConfig.uploadImgHooks = {
        before: function (xhr, editor, files) {
          console.log('files', files)
          // 图片上传之前触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件
          // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
          // return {
          //     prevent: true,
          //     msg: '放弃上传'
          // }
        },
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功，插入图片的回调
          // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

          // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
          var urlList = result.body
          urlList.forEach(element => {
            insertImg(element.fileUrl)
          })
          // result 必须是一个 JSON 格式字符串！！！否则报错
        }
      }
      this.editor.customConfig.onchange = html => {
        this.isChange = true
        this.editorContent = html // 绑定当前逐渐地值
        // wangeditor 内每一行都由 <P></P> 标签包裹起来、
        // wangeditor 判断富文本编辑器为非空，需要处理 <p><br></p> 和 <p>&nbsp;</p>
        this.$emit('change', this.editorContent) // 将内容同步到父组件中
      }
      this.editor.customConfig.pasteFilterStyle = false
      this.editor.customConfig.pasteTextHandle = function (content) {
        if (content == '' && !content) return ''
        var str = content
        str = str.replace(/<xml>[\s\S]*?<\/xml>/ig, '')
        str = str.replace(/<style>[\s\S]*?<\/style>/ig, '')
        str = str.replace(/<\/?[^>]*>/g, '')
        str = str.replace(/[ | ]*\n/g, '\n')
        str = str.replace(/&nbsp;/ig, '')
        return str
      }

      // 创建富文本编辑器
      this.editor.create()
      this.editor.txt.html(this.value)
    },
    userAppend () {
      this.editor.txt.append('<p>________</p>')
    }
    // lineClick () {
    //   console.log('123')
    // },
    // lineMouse (event) {
    //   console.log('456', event)
    // }
  }
}
</script>

<style lang="scss" scoped>
.editor {
  position: relative;
  // width: 710px
  // margin: 0 auto;
}
.toolbar {
  border: 1px solid #ccc;
}
.text {
  border: 1px solid #ccc;
  // height: 350px;
}

.editor_line {
  position: absolute;
  bottom: 0;
  left: 2;
  z-index: 999999999999;
  width: 100%;
  height: 2px;
  background: #ccc;
  margin-top: 0px;
  cursor: s-resize;
}
</style>
